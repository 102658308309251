$btn-light-color:                #585161;
$btn-light-bg:                   #fff;
$btn-light-border:               #dcdcdc;
$btn-light-hover-color:          #191919;
$btn-light-gradient-start:       #f5f5f5;
$btn-light-gradient-end:         #f1f1f1;
$btn-light-hover-gradient-start: #f8f8f8;
$btn-light-hover-border:         #c6c6c6;

$btn-outline-secondary-border:         #ccc;
$btn-outline-secondary-border-hover:   #adadad;

.btn-outline-secondary {
  background-color: #fff;
  color: $body-color;
  border-color: $btn-outline-secondary-border;

  &:hover,
  &:active,
  &:focus {
    background-color: $gray-275;
    border-color: $btn-outline-secondary-border-hover;
    color: $body-color;
  }

  &:disabled, &.disabled {
    cursor: not-allowed;
    color: $btn-light-color;
    border-color: $btn-light-border;
    filter: alpha(opacity=65);
    opacity: 0.65;
    box-shadow: none;
  }
}

.btn-light {
  @include gradient-y($btn-light-gradient-start, $btn-light-gradient-end, 0, 100%);
  border-color: $btn-light-border;
  color: $btn-light-color;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    color: $body-color;
  }

  &:disabled, &.disabled {
    color: $btn-light-color;
    border-color: $btn-light-border;
    opacity: 0.65;
  }
}

.btn-light:hover,
.show > .btn-light.dropdown-toggle {
  @include gradient-y($btn-light-hover-gradient-start, $btn-light-gradient-end, 0, 100%);
  border-color: $btn-light-hover-border;
}

.btn-subnav-size {
  padding: 0.1875rem 1.125rem;
}

.btn-table-size {
  min-width: 3.25rem;
  padding: 0.125rem .75rem;
  line-height: 1.5;
  border-radius: 1px;
}

.btn-xs {
  padding: 0.125rem 0.5rem;
  font-size: 0.625rem;
  line-height: 1.5;
}

.link-dark {
  color: $body-color !important;

  &:hover,
  &:focus {
    color: $body-color !important;
  }
}

.link-light {
  color: $gray-350 !important;

  &:hover,
  &:focus {
    color: $gray-550 !important;
  }
}

.link-disabled {
  color: $gray-350;
  pointer-events: none;

  &:hover,
  &:focus {
    color: $gray-550;
  }
}

.link-light-danger {
  color: $gray-350;

  &:hover,
  &:focus {
    color: $danger;
  }
}

//
// Pills
//

//.nav-pills {
//  .nav-item {
//    margin-bottom: $spacer * .25;
//  }
//  .nav-link {
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//    color: $body-color;
//    transition: all .2s;
//
//    &:hover {
//      color: $white;
//      background: $gray-600;
//    }
//  }
//
//  .nav-link.active,
//  .show > .nav-link {
//    color: $nav-pills-link-active-color;
//    background-color: $nav-pills-link-active-bg;
//  }
//}



$nav-pills-padding-y:                0.3125rem;
$nav-pills-padding-x:                0.875rem;

//
// Pills
//

.nav-pills {
  .nav-link {
    background: none;
    border: 0;
    @include border-radius($nav-pills-border-radius);
    color: $body-color;
    display: flex;
    align-items: center;
    padding: $nav-pills-padding-y $nav-pills-padding-x;
    transition: all .2s;
    margin-bottom: $spacer * .25;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $white;
      background: $gray-550;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    @include gradient-bg($nav-pills-link-active-bg);
  }
}
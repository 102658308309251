.bg-danger-light {
  background-color: fade-out($red, 0.75) !important;
}

.bg-prominent {
  background-color: $body-color !important;
}

.bg-teal {
  background-color: $teal !important;
}

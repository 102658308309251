@import "styles/config";

.AlertPage {
  padding: 4.375rem 0 $spacer * 10;
  text-align: center;
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;

  &__header {
    margin-bottom: 3.125rem;
  }

  &__title {
    margin-top: 0;
    margin-bottom: $spacer;
    font-weight: 100;
    font-size: 2.5rem;
    letter-spacing: -1px;
    line-height: 1.1;
  }

  &__description {
    color: $text-muted;
    font-size: 0.875rem;
  }
}

.table-col {
  white-space: nowrap;

  &-checkbox {
    text-align: center;
    padding: $table-cell-checkbox-padding-y $table-cell-padding-x !important;
    width: $form-check-input-width + .125rem + ($table-cell-padding-x * 2);
  }

  &-picture {
    text-align: center;
    padding: $table-cell-picture-padding-y $table-cell-padding-x !important;
    width: $table-image-size + ($table-cell-padding-x * 2);
    min-width: $table-image-size + ($table-cell-padding-x * 2);
  }

  &-button {
    text-align: center;
    width: 7.25rem;

    &--sm {
      width: 5.625rem;
    }

    &--dropdown {
      width: 4.75rem;
    }
  }

  &-dropdown-link {
    padding-top: $table-cell-padding-y - 0.125rem !important;
    padding-bottom: $table-cell-padding-y - 0.125rem !important;
  }

  &-label-xs {
    text-align: center;
    width: 4.75rem;
  }

  &-label-sm {
    text-align: center;
    width: 5.625rem;
  }

  &-label-md {
    text-align: center;
    width: 7.5rem;
  }

  &-label-lg {
    text-align: center;
    width: 8.75rem;
  }

  &-label-xl {
    text-align: center;
    width: 10rem;
  }

  &-details-xs {
    width: 4.75rem;
  }

  &-details-sm {
    width: 6.875rem;
  }

  &-details-md {
    width: 8.75rem;
  }

  &-details-mdx {
    width: 10rem;
  }

  &-details-lg {
    width: 12.5rem;
  }

  &-progress {
    width: 6.25rem;
    text-align: center
  }

  &-clickable {
    cursor: pointer;
  }

  &-hover-clickable {
    cursor: pointer;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: $gray-350 !important;
    }
  }

  // A link which spans a table cell.
  // The table cell should have 0 padding.
  &__stretch-link {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: $table-cell-padding-y $table-cell-padding-x;

    &:focus-visible {
      background-color: $gray-350;
      outline: none !important;
    }
  }
}

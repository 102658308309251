.expand-up {
  animation-name: expand-up;
  -webkit-animation-name: expand-up;

  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}

@keyframes expand-up {
  0% {
    transform: translateY(100%) scale(0.6) scaleY(0.5);
  }
  60% {
    transform: translateY(-7%) scaleY(1.12);
  }
  75% {
    transform: translateY(3%);
  }
  100% {
    transform: translateY(0%) scale(1) scaleY(1);
  }
}

@-webkit-keyframes expand-up {
  0% {
    -webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
  }
  60% {
    -webkit-transform: translateY(-7%) scaleY(1.12);
  }
  75% {
    -webkit-transform: translateY(3%);
  }
  100% {
    -webkit-transform: translateY(0%) scale(1) scaleY(1);
  }
}

.form-label {
  &--light {
    font-weight: normal;
  }
}

.form-text {
  display: block;
  margin-top: 0;
  margin-bottom: $form-text-margin-bottom;
  font-size: inherit;
  color: $text-muted;
}

.form-control-profile {
  height: 41px;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 1.33;
  box-shadow: none;
  border-radius: 0;
}

.form-control-profile-size {
  @include media-breakpoint-up(md) {
    width: 50%;
  }
  @include media-breakpoint-up(xl) {
    width: 33.3333%;
  }
}

.form-switch.form-switch-reverse {
  padding-right: $form-switch-padding-start;
  padding-left: 0;

  .form-check-label {
    width: 100%;
  }

  .form-check-input {
    float: right;
    margin-right: $form-switch-padding-start * -1;
  }
}

.badge--lg {
  padding: 0.3rem 0.4875rem 0.39rem;
  font-weight: 600;
  @include font-size($font-size-base);
  @include border-radius(0.1875rem);
  @include gradient-bg();
}

.badge--xl {
  //padding: 0.3rem 0.4875rem 0.39rem;
  padding: 0.1625rem 0.4875rem 0.24375rem;
  font-weight: 600;
  line-height: 1.5;
  @include font-size($font-size-base);
  @include border-radius(0.1875rem);
  @include gradient-bg();
}

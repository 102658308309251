@import "styles/config";

.ProfilePicture {
  display: inline-block;
  vertical-align: inherit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 1;
  width: $spacer * 3.75;
  height: $spacer * 3.75;
  max-width: 100%;

  &--error,
  &--placeholder {
    background-color: $gray-200;
  }

  &--placeholder {
    display: inline-block;
  }

  &--xs {
    width: $table-image-size;
    height: $table-image-size;
  }

  &--xs.ProfilePicture--border {
    border-width: 2px;
  }

  &--sm {
    width: 3rem; // 48px
    height: 3rem; // 48px
  }

  &--md {
    width: 8rem;
    height: 8rem;
  }

  &--sm.ProfilePicture--border {
    border-width: 2px;
  }

  &--xmd {
    width: $spacer * 6.5;
    height: $spacer * 6.5;
  }

  &--lg {
    width: $spacer * 12;
    height: $spacer * 12;
  }

  &--xl {
    width: 7.875rem;
    height: 7.875rem;
  }

  &--xxl {
    width: $spacer * 25.7;
    height: $spacer * 25.7;

    @include media-breakpoint-down(sm) {
      width: $spacer * 15;
      height: $spacer * 15;
    }
  }

  &--round {
    border-radius: 50%;
  }

  &--table {
    vertical-align: middle;
  }

  &--border {
    border: 6px solid white;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  }
}

.ProfilePictureError__icon {
  flex: auto;
  color: $danger;
  font-size: $font-size-base;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

// Import local variables
@import "config";

// Import the default bootstrap styles
@import "base/bootstrap";

// Components
@import "components/container";
@import "components/dropdown";
@import "components/navbar";
@import "components/button";
@import "components/badge";
@import "components/link";
@import "components/forms";
@import "components/form-check";
@import "components/table";
@import "components/table-cols";
@import "components/nav";
@import "components/layout";
@import "components/background";

// Animations
@import "animations/expand-up";

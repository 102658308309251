
// Single container class with breakpoint max-widths
.large-container {
  @include make-container();
}

// Responsive containers that are 100% wide until a breakpoint
@each $breakpoint, $container-max-width in $large-container-max-widths {
  .large-container-#{$breakpoint} {
    @extend .container-fluid;
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints-lg) {
    %responsive-large-container-#{$breakpoint} {
      max-width: $container-max-width;
    }

    // Extend each breakpoint which is smaller or equal to the current breakpoint
    $extend-breakpoint: true;

    @each $name, $width in $grid-breakpoints-lg {
      @if ($extend-breakpoint) {
        .large-container#{breakpoint-infix($name, $grid-breakpoints-lg)} {
          @extend %responsive-large-container-#{$breakpoint};
        }

        // Once the current breakpoint is reached, stop extending
        @if ($breakpoint == $name) {
          $extend-breakpoint: false;
        }
      }
    }
  }
}

.navbar .large-container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

// Wyzed-specific variables
//
// Variables that were not originally defined in `_variables.scss`
@use "sass:math";

$navbar-background: #F1F1F1;

// Tables

$table-image-size: 2rem; // 32px
$table-row-height: ($font-size-base * $line-height-base) + ($table-cell-padding-y * 2);
$table-cell-picture-padding-y: math.div($table-row-height - $table-image-size, 2);
$table-cell-checkbox-padding-y: math.div(2rem - $form-check-input-width, 2);  // NOTE: 2rem used to be 2em

// Forms

$form-switch-height: 1.5rem;
$form-switch-checked-color: $success;
$form-switch-background-color: $gray-350;

// Container

$grid-breakpoints-lg: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1200px,
        xl: 1400px,
        xxl: 1600px
);
@include _assert-ascending($grid-breakpoints-lg, "$grid-breakpoints-lg");
@include _assert-starts-at-zero($grid-breakpoints-lg, "$grid-breakpoints-lg");

$large-container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: map-get($grid-breakpoints-lg, 'lg'),
        xl: map-get($grid-breakpoints-lg, 'xl'),
        xxl: map-get($grid-breakpoints-lg, 'xxl'),
);
@include _assert-ascending($large-container-max-widths, "$large-container-max-widths");

.navbar {
  justify-content: flex-start; // space out brand from logo
  @include media-breakpoint-down(sm) {
    justify-content: space-between; // space out brand from logo
  }
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-left: $navbar-brand-margin-end;
}

.navbar-nav {
  .dropdown-menu.navbar__dropdown-menu {
    margin-top: $navbar-padding-y * 2.25 !important;
    @include box-shadow(0 6px 12px rgba($black, .1));
  }
}
